import React, { useContext } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { stub as $t } from '@nbcnews/analytics-framework';

import {
  waffleCategories,
  package as ContentPropType,
} from 'lib/CustomPropTypes';
import BTE from 'lib/BTE';
import Breakpoints from 'lib/Breakpoints';
import { ECOMMERCE_DISCLAIMER } from 'lib/commerceUtils';

import { DisclaimerModal } from 'components/Disclaimer';
import { CollectionLead } from 'components/packages/CollectionLead';
import { Filter } from 'components/packages/Waffle/Filter';
import { VerticalContext } from 'lib/ContextTypes';

import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { PRODUCT_WAFFLE_DISCLAIMER_LINK } from 'lib/brandFeatures';
import styles from './styles.module.scss';

$t('register', 'waffle_filters');

class Header extends React.Component {
  static propTypes = {
    activeFilters: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    cardCount: PropTypes.number,
    categories: waffleCategories,
    categoryToDisplay: PropTypes.objectOf(PropTypes.string),
    clearFilter: PropTypes.func,
    content: ContentPropType.isRequired,
    enableMultiselectFilters: PropTypes.bool,
    filterDisplayNameByKey: PropTypes.objectOf(PropTypes.object),
    filterOnChange: PropTypes.func,
    filterValues: PropTypes.objectOf(PropTypes.objectOf(PropTypes.bool)),
    isFilterActive: PropTypes.bool,
    onFilterClose: PropTypes.func,
    onResize: PropTypes.func,
    showDisclaimer: PropTypes.bool,
  };

  static contextTypes = {
    vertical: PropTypes.string,
  };

  static defaultProps = {
    activeFilters: [],
    cardCount: 0,
    categories: {},
    categoryToDisplay: {},
    clearFilter: () => () => { },
    enableMultiselectFilters: true,
    filterDisplayNameByKey: {},
    filterOnChange: () => () => { },
    filterValues: {},
    isFilterActive: false,
    showDisclaimer: false,
    onFilterClose: () => { },
    onResize: () => { },
  };

  constructor(props) {
    super(props);
    this.state = {
      filterOverlayOpen: false,
      activeFilterKey: null,
      isSorM: Breakpoints.isSorM(),
    };
  }

  componentDidMount() {
    BTE.monitorResize();
    BTE.on('resize', this.handleResize, 'debounce');
  }

  componentWillUnmount() {
    BTE.remove('resize', this.handleResize, 'debounce');
  }

  handleResize = () => {
    const isSorM = Breakpoints.isSorM();
    const { onResize } = this.props;
    onResize();
    this.setState({ isSorM });
    if (isSorM) {
      this.closeFilterOverlay();
    }
  };

  openFilterOverlay = () => {
    this.setState({ filterOverlayOpen: true });
    document.getElementsByTagName('body')[0].classList.add('verticalScrollDisabled');
    $t('track', 'waffle_filters', {
      action: 'open',
      view: 'accordion',
    });
  };

  closeFilterOverlay = () => {
    this.clearActiveAccordion();
    this.setState({ filterOverlayOpen: false });
    document.getElementsByTagName('body')[0].classList.remove('verticalScrollDisabled');
    $t('track', 'waffle_filters', {
      action: 'done',
      view: 'accordion',
    });
  };

  /**
   *
   */
  trackClearFilter = () => {
    const isSorM = Breakpoints.isSorM();
    $t('track', 'waffle_filters', {
      action: 'clear',
      view: isSorM ? 'accordion' : 'dropdown',
    });
  }

  /**
   * Add tracking when a user clears a filter with the x button
   * @param {string} category the category of the filter that's being cleared
   */
  trackFilterOnChange = (category) => {
    $t('track', 'waffle_filters', {
      action: 'remove filter',
      filter: category,
    });
  }

  setDesktopActiveAccordion = (key) => {
    this.setState({ activeFilterKey: key });
  };

  clearActiveAccordion = () => {
    this.setState({ activeFilterKey: null });
  };

  switchFilterOverlayUI = () => {
    if (typeof document === 'undefined') return;
    const elementsToFindFlex = ['#hfs-header'];
    const elementsToFindBlock = ['.breaking-marquee'];
    const { filterOverlayOpen } = this.state;

    elementsToFindFlex.forEach((item) => {
      const element = document.querySelector(item);
      if (element) element.style.display = filterOverlayOpen ? 'none' : 'flex';
    });

    elementsToFindBlock.forEach((item) => {
      const element = document.querySelector(item);
      if (element) element.style.display = filterOverlayOpen ? 'none' : 'block';
    });
  };

  tagList = () => {
    const {
      activeFilters,
      clearFilter,
      filterOnChange,
      enableMultiselectFilters = {},
      categoryToDisplay,
    } = this.props;

    const clearText = enableMultiselectFilters
      ? i18next.t('Clear All')
      : i18next.t('Clear Filter');

    const tagList = activeFilters.map(({ key, category, displayName }) => (
      <button
        data-testid="waffleHeader__activeFilter"
        data-filter-action="remove"
        className={styles.tagListBtn}
        key={key}
        onClick={() => {
          filterOnChange(category)(key, false);
          this.trackFilterOnChange(categoryToDisplay[category]);
        }}
        type="button"
      >
        <span className={classNames(styles.iconDelete, 'icon icon-close')} />
        {displayName}
      </button>
    ));

    return (
      <>
        <div className={styles.tagList}>
          {tagList}
        </div>
        <button
          className={styles.clearAllBtn}
          data-testid="waffleHeader__clear-all"
          data-filter-action="clear"
          onClick={() => {
            clearFilter();
            this.trackClearFilter();
          }}
          type="button"
        >
          {clearText}
        </button>
        <span className={styles.resultsSeparator}>|</span>
      </>
    );
  };

  renderDek = () => {
    const {
      content: {
        metadata: {
          description,
          shortDescription,
          disclaimerKey,
          disclaimerOverride,
        } = {},
        subType,
        type,
      } = {},
      showDisclaimer,
    } = this.props;

    if (!description && !shortDescription && !showDisclaimer) {
      return null;
    }

    const isProductWaffle = type === 'waffle'
      && ['autofilledProductWaffle', 'giftGuideWaffle'].includes(subType);
    const shouldShowDisclaimerModal = showDisclaimer && !isProductWaffle;
    const shouldShowDisclaimerText = showDisclaimer && isProductWaffle;

    function GetDisclaimer(key, override) {
      const vertical = useContext(VerticalContext);

      const defaultDisclaimer = `${vertical === 'today' ? 'Shop TODAY' : 'NBC Select'} independently determines what we cover and recommend.`
        + ' When you buy through our links, we earn a commission.'
        + ` <a href=${getFeatureConfigForBrand(PRODUCT_WAFFLE_DISCLAIMER_LINK, vertical)} target="_blank">Learn more.</a>`;

      const hasOverride = key === 'OVERRIDE'
        && typeof override === 'string'
        && override?.length > 0;

      if (hasOverride) {
        return override;
      }
      if (key === 'NONE') return null;
      if (key === 'DEFAULT') {
        return defaultDisclaimer;
      }
      if (key === 'PRODUCT') {
        return 'We may earn a commission when you purchase through our links.';
      }
      return ECOMMERCE_DISCLAIMER[key] || defaultDisclaimer;
    }
    const disclaimerHTML = GetDisclaimer(disclaimerKey, disclaimerOverride);

    return (
      <div className={styles.dek}>
        {(description || shortDescription) && (
          <p className={styles.description}>
            {shortDescription && (
              <span className={styles.short}>{shortDescription}</span>
            )}
            {description && (
              <span className={styles.long}>{description}</span>
            )}
          </p>
        )}
        {shouldShowDisclaimerModal && (
          <div className={styles.disclaimerModal}>
            <DisclaimerModal
              disclaimerKey={disclaimerKey}
              disclaimerOverride={disclaimerOverride}
            />
          </div>
        )}
        {shouldShowDisclaimerText && (
          <div
            className={styles.disclaimerText}
            data-testid="waffle-header__disclaimer"
            dangerouslySetInnerHTML={{
              __html: disclaimerHTML,
            }}
          />
        )}
      </div>
    );
  };

  mobileFilters = () => (
    <button
      data-filter-action="open"
      className={styles.mobileCTABtn}
      onClick={this.openFilterOverlay}
      type="button"
    >
      Filter
      {' '}
      <span className="icon icon-angle-down" />
    </button>
  );

  filters = () => {
    const {
      filterOverlayOpen,
      activeFilterKey,
      isSorM,
    } = this.state;
    const {
      activeFilters,
      categories,
      categoryToDisplay,
      clearFilter,
      enableMultiselectFilters,
      filterDisplayNameByKey,
      filterOnChange,
      filterValues,
      content: {
        metadata: {
          description,
          shortDescription,
        },
      },
      onFilterClose,
    } = this.props;

    const clearText = enableMultiselectFilters
      ? i18next.t('CLEAR ALL')
      : i18next.t('CLEAR');
    const categoryEntries = Object.entries(categories);
    const onlyOneFilter = categoryEntries.length === 1;

    return (
      <>
        <div className={classNames(
          styles.tester,
          {
            [styles.open]: filterOverlayOpen,
          },
        )}
        />
        <div
          className={classNames(
            styles.overlayBg,
            {
              [styles.open]: filterOverlayOpen,
            },
          )}
          aria-hidden="true"
          onClick={() => {
            this.closeFilterOverlay();
            this.clearActiveAccordion();
          }}
          data-testid="waffle-header__overlay-bg"
        />
        <div
          className={classNames(
            styles.filters,
            {
              [styles.open]: filterOverlayOpen,
              [styles.afterDek]: (shortDescription || description),
            },
          )}
        >
          {categoryEntries.map(([category, values]) => (
            <Filter
              mobileFiltersEnabled
              onlyOneFilter={onlyOneFilter}
              categoryName={category}
              categoryToDisplay={categoryToDisplay}
              categoryValues={values}
              enableMultiselectFilters={enableMultiselectFilters}
              filterDisplayNameByKey={filterDisplayNameByKey}
              key={category}
              onChange={filterOnChange(category)}
              value={filterValues[category] || {}}
              activeFilterKey={activeFilterKey}
              isOpen={activeFilterKey === category}
              isSorM={isSorM}
              setDesktopActiveAccordion={(key) => {
                this.setDesktopActiveAccordion(key);
              }}
              clearActiveAccordion={this.clearActiveAccordion}
              onFilterClose={onFilterClose}
            />
          ))}
          <div className={styles.overlayBtnWrapper}>
            <button
              type="button"
              disabled={!activeFilters.length}
              className={classNames(
                styles.overlayBtn,
                styles.secondary,
                {
                  [styles.disabled]: !activeFilters.length,
                },
              )}
              onClick={() => {
                clearFilter();
                this.trackClearFilter();
              }}
              aria-label="Clear all filter selections"
            >
              {clearText}
            </button>
            <button
              className={classNames(
                styles.overlayBtn,
                styles.primary,
                styles.doneBtn,
              )}
              onClick={this.closeFilterOverlay}
              type="button"
              aria-label="Apply filters "
            >
              {i18next.t('DONE')}
            </button>
          </div>
        </div>
      </>
    );
  };

  render() {
    const {
      categories, cardCount, isFilterActive, content,
    } = this.props;

    this.switchFilterOverlayUI();

    const filters = Object.keys(categories);
    const hasFilters = !!filters.length;
    const filtersHaveParams = filters.some(
      (filter) => !!categories[filter].length,
    );

    const results = (
      <span className={styles.resultsCount} data-testid="result-count" data-count={cardCount}>
        {cardCount.toLocaleString()}
        {' '}
        {i18next.t(cardCount === 1 ? 'Result' : 'Results')}
      </span>
    );

    const contentWithoutDisclaimer = {
      ...content,
      metadata: { ...(content.metadata || {}), disclaimerKey: null },
    };

    return (
      <div data-testid="waffleHeader" className={styles.waffleHeader}>
        <CollectionLead
          content={contentWithoutDisclaimer}
          dekRenderer={this.renderDek}
          disclaimerKey="NONE"
          pkgClassName={styles.waffleHeaderCollectionLead}
          postContentRenderer={() => (filtersHaveParams && hasFilters ? (
            <>
              {this.filters()}
              <div className={styles.results}>
                {isFilterActive && this.tagList()}
                {results}
              </div>
            </>
          ) : null)}
        />
        {filtersHaveParams && hasFilters && (
          <div className={styles.mobileFiltersCTA}>
            {this.mobileFilters()}
            <div className={styles.results}>
              {isFilterActive && this.tagList()}
              {results}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { Header };
