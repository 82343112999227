import React from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import classNames from 'classnames';
import { StoryItem } from './StoryItem';
import { LeadItem } from './LeadItem';
import { parseLayoutTemplate } from '../utils/parseLayoutTemplate';
import './styles.themed.scss';
import { ColumnsContainer } from '../ColumnsContainer';
import { ColumnOfItems } from '../ColumnOfItems';
import { findLeadItemInfo } from '../utils/containerFunctions';
import { getPackageData } from '../utils/packageDataManager';
import { getIs2024ScoreboardEmbed } from '../utils/getIs2024ScoreboardEmbed';

/**
 * @param {object} root0
 * @param {import('lib/CustomPropTypes/package').PackageType} root0.content
 * @returns {React.ReactElement} Component
 */
export function MultiStorylineContainer({ content }) {
  if (!content) return null;
  const {
    items,
    metadata,
  } = content;

  if (!items || !metadata) return null;

  const {
    pkgHeaderStyle,
    playmakerToggle,
    playmakerColumn,
    showVerticalBorderA = true,
    showVerticalBorderB = true,
    stackLeadColumnFirstOnMobile,
    column1Title,
    column1Logo,
    column2Title,
    column2Logo,
    column3Title,
    column3Logo,
    contentAsPackageHeadline,
    leadHeadlineSize = 'medium',
    showBottomBorder = true,
  } = metadata;

  const {
    leadColumn,
    columns,
  } = getPackageData(content, 'multiStorylineColumnInfo');

  const {
    zoneLayoutType,
    zoneRailAdjacent,
    zoneIsRail,
  } = getPackageData(content, 'zoneLayoutInfo');

  const hasColumn1Lead = leadColumn === 'Column1Lead';
  const hasColumn2Lead = leadColumn === 'Column2Lead';
  const hasColumn3Lead = leadColumn === 'Column3Lead';
  const hasLeadColumn = hasColumn1Lead || hasColumn2Lead || hasColumn3Lead;
  let currentItemIndex = 0;
  let itemIndexInColumn = 0;
  // Get Layout Info
  // note: parseLayoutTemplate is looking for layoutTemplate but can depend on other metadata if needed.
  const layoutDef = parseLayoutTemplate(metadata);

  if (!layoutDef) {
    /* eslint-disable-next-line no-console */
    console.error('MultiStoryline ERROR: Invalid Layout');
    return null;
  }
  const { itemsPerColumn } = layoutDef;

  const leadHeadlineItem = findLeadItemInfo(
    leadHeadlineSize,
    contentAsPackageHeadline,
    leadColumn,
    items,
    metadata,
  );

  // Put the items into their column lists.
  const itemsCopy = [...leadHeadlineItem.edittedItems];

  const itemsByColumn = itemsPerColumn.reduce((ac, numItemsInColumn, index) => {
    for (let i = 0; i < numItemsInColumn; i += 1) {
      const colItems = ac[index] || [];
      const item = itemsCopy.shift();
      colItems.push(item);
      // eslint-disable-next-line no-param-reassign
      ac[index] = colItems;
    }
    return ac;
  }, []);

  // We track itemIndex, the index from the original items array,
  // because LiveBlog currently needs it. That could be changed but
  // arguably the item might want to know this information.
  const columnsJsx = itemsByColumn.reduce((ac, itemList, columnIndex) => {
    const columnNumber = columnIndex + 1;
    itemIndexInColumn = 0;
    const columnName = `Column${columnNumber}`;
    const columnClass = `items-column-${columnNumber}`;
    const numItems = itemList.length;
    const numItemsTriple = numItems % 3 === 0;
    const numItemsEven = numItems % 2 === 0;
    const itemsCountClass = `items-count-class-${numItems < 3 ? numItems : '3plus'}`;
    // playmakerColumn value will include column number with with Top or Bottom at the end of the value
    const showLive = playmakerToggle && playmakerColumn.includes(columnName);
    const showLiveIndex = showLive && playmakerColumn.includes('Top') ? 0 : numItems - 1;
    const isLeadColumn = leadColumn === `${columnName}Lead`;
    const isNotLeadColumn = leadColumn && !isLeadColumn;
    const innerColumnJoin = columnNumber < columns;
    const showBothVerticalBorders = (showVerticalBorderA && showVerticalBorderB);
    const showNoVerticalBorders = (!showVerticalBorderA && !showVerticalBorderB);
    const showOneVerticalBorder = !showBothVerticalBorders && !showNoVerticalBorders;
    const columnHasFlexItem = itemList.some(getIs2024ScoreboardEmbed);

    // rowModeGroupNumber calc, syncs with classes to make the columns-in-row-mode
    let rowModeGroupNumber = 3;
    if (numItems < 3) {
      // 1 or 2 items, use 2 columns
      rowModeGroupNumber = 2;
    } else if (numItemsTriple) {
      // make sure if evenly divisible by 3, it's 3 columns
      rowModeGroupNumber = 3;
    } else if (numItemsEven) {
      // if evenly divisible by two and not by three, use 2 columns
      // currently with our max of 7 items, only affect 4 items.
      rowModeGroupNumber = 2;
    }

    let currentColumnTitle;
    let currentColumnLogo;
    switch (columnIndex) {
      case 0:
        currentColumnTitle = column1Title;
        currentColumnLogo = column1Logo;
        break;
      case 1:
        currentColumnTitle = column2Title;
        currentColumnLogo = column2Logo;
        break;
      default:
        currentColumnTitle = column3Title;
        currentColumnLogo = column3Logo;
    }
    const itemEl = (
      <ColumnOfItems
        key={`${columnName}`}
        columnName={columnName}
        horizontalItemDividerClassName="horizontal-item-divider"
        rowModeDividerClassName="row-mode-horizontal-divider"
        rowModeGroupNumber={rowModeGroupNumber}
        columnTitle={currentColumnTitle}
        columnLogo={currentColumnLogo}
        className={classNames(
          'multistory-items-column',
          columnClass, // items-column-N
          itemsCountClass, // needed for column-3 rearrange
          {
            [`coi-zone-layout-${zoneLayoutType}`]: true,
            'coi-zone-layout-not-fullWidth': zoneLayoutType !== 'fullWidth',
            'coi-zone-is-rail-adjacent': zoneRailAdjacent,
            'coi-zone-is-rail': zoneIsRail,
            'items-count-is-triple': numItemsTriple,
            'items-count-is-even': numItemsEven,
            'two-column': columns === 2,
            'three-column': columns === 3,
            'show-vertical-border-1': showVerticalBorderA,
            'show-vertical-border-2': showVerticalBorderB,
            'both-vertical-borders': showBothVerticalBorders,
            'one-vertical-border': showOneVerticalBorder,
            'lead-column': isLeadColumn,
            'not-lead-column': isNotLeadColumn,
            'has-lead-column': hasLeadColumn,
            'no-lead-column': !hasLeadColumn,
            'before-inner-vertical-join': innerColumnJoin,
            'has-flex-item': columnHasFlexItem,
          },
        )}
      >
        {
          itemList.reduce((jsxList, itemData, index) => {
            const storyItemJsx = itemData && (
              <StoryItem
                className={classNames(
                  'multistory-item',
                  {
                    'row-mode-860': hasLeadColumn && columnNumber === 3,
                  },
                )}
                key={`${itemData.id}-${columnName}`}
                content={content}
                itemData={itemData}
                showLive={showLive && showLiveIndex === index}
                columnIndex={columnIndex}
                itemIndex={currentItemIndex}
                itemIndexInColumn={itemIndexInColumn}
                showVerticalBorder={showVerticalBorderA}
                hideHeadlineAndDek={
                  leadHeadlineItem?.columnLeadIndex === columnIndex
                  && index === 0
                  && leadHeadlineItem.removed !== true
                }
              />
            );

            // the currentItemIndex has to be tracked because
            // it's needed when the content item is a live blog.
            // <LiveBlog> gets the item from package data itself
            // at the given itemIndex.

            currentItemIndex += 1;
            itemIndexInColumn += 1;
            jsxList.push(storyItemJsx);

            return jsxList;
          }, [])
        }
      </ColumnOfItems>
    );
    ac.push(itemEl);

    return ac;
  }, []);

  const verticalDividersList = columns === 3
    ? [showVerticalBorderA, showVerticalBorderB] : [showVerticalBorderA];
  return (
    <div
      className={classNames(
        'multistory-container',
        {
          'two-column': columns === 2,
          'three-column': columns === 3,
          'has-lead-column': hasLeadColumn,
          'no-lead-column': !hasLeadColumn,
          'package-go-big': pkgHeaderStyle === 'Go Big',
        },
      )}
      data-testid="multi-storyline-container"
    >
      <LeadItem leadItemContent={leadHeadlineItem} />
      <ColumnsContainer
        className={classNames(
          {
            [`cc-zone-layout-${zoneLayoutType}`]: true,
            'cc-zone-layout-not-fullWidth': zoneLayoutType !== 'fullWidth',
            'cc-zone-is-rail-adjacent': zoneRailAdjacent,
            'cc-zone-is-rail': zoneIsRail,
          },
        )}
        leadColumn={leadColumn}
        stackLeadColumnFirstOnMobile={stackLeadColumnFirstOnMobile}
        verticalDividers={verticalDividersList}
      >
        {columnsJsx}
      </ColumnsContainer>
      { showBottomBorder && (<hr className="pkg-bottom-border" />)}
    </div>
  );
}

MultiStorylineContainer.propTypes = {
  content: PackagePropType.isRequired,
};
