/* eslint-disable jsdoc/require-jsdoc */
/* About containerFunction.js
    These are for MSP Container and are ways of accessing
    the lead item for using it as package headline when selected
*/

import { BADGES } from 'components/BadgeLabel/constants';


/**
 *
 * @param {string} leadHeadlineSize
 * @param {boolean} contentAsPackageHeadline
 * @param {string} leadColumn
 * @param {Array} items
 * @param {object} metadata
 * @returns {object}
 */

// find lead item info for when contentAsPackageHeadline is selected
export const findLeadItemInfo = (leadHeadlineSize, contentAsPackageHeadline, leadColumn, items, metadata) => {
  const noLead = leadColumn === undefined;
  const hasColumn1Lead = leadColumn === 'Column1Lead';
  const hasColumn2Lead = leadColumn === 'Column2Lead';
  const numOfItemsCol1 = metadata?.numberOfItemsInColumn1 ?? 1;
  const numOfItemsCol2 = metadata?.numberOfItemsInColumn2 ?? 1;
  const prevItemsNum = numOfItemsCol1 + numOfItemsCol2;
  let leadItemValues = {};
  // Need items assigned to a separate edittedItems variable to keep track of modified items in parent component
  let edittedItems = items;
  const columnLeads = {
    Column1Lead: 0,
    Column2Lead: numOfItemsCol1,
    Column3Lead: prevItemsNum,
  };
  const leadIndex = columnLeads[leadColumn];
  const leadItem = items[leadIndex];

  if (contentAsPackageHeadline && !noLead) {
    leadItemValues = {
      headlineSize: leadHeadlineSize,
      // eslint-disable-next-line no-nested-ternary
      columnLeadIndex: hasColumn1Lead ? 0 : hasColumn2Lead ? 1 : 2,
      removed: false,
      valueRemoved: leadIndex,
      item: leadItem ?? null,
    };
    if ((!leadItem?.computedValues.teaseImage
      || leadItem?.metadata?.mediaSize === 'multiSmall')
      && leadItem?.type !== 'liveBlog'
      && leadItem?.type !== 'custom') {
      leadItemValues.removed = true;
      // eslint-disable-next-line no-nested-ternary
      edittedItems = hasColumn1Lead
        ? items.slice(1)
        : hasColumn2Lead
          ? items.slice(0, numOfItemsCol1).concat(items.slice(numOfItemsCol1 + 1))
          : items.slice(0, prevItemsNum).concat(items.slice(prevItemsNum + 1));
    }
  }

  leadItemValues.edittedItems = edittedItems;
  return leadItemValues;
};

/**
 *
 * @param {string} badge
 * @param {boolean} hasBadge
 * @returns {string}
 */

// find badge type for MSPContainer
export const getBadgeType = (badge, hasBadge) => {
  if (hasBadge && !badge) {
    return 'BREAKING';
  }
  return (hasBadge && badge) || BADGES.EYEBROW;
};
