import i18next from 'i18next';
import classNames from 'classnames';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import TitleLogo from 'components/TitleLogo';

import './styles.themed.scss';
import { VerticalContext } from 'lib/ContextTypes';

export function PackageTitleTopBorder(props) {
  const vertical = useContext(VerticalContext);
  const {
    additionalClasses = '',
    logoUrl = '',
    seeAllText = '',
    seeAllUrl = '',
    title = '',
    icid = null,
    HTag = 'h2',
  } = props;

  if (!title && !logoUrl) {
    return null;
  }

  const headingClasses = classNames(
    'package-title-top-border__title',
  );

  const containerClasses = classNames(
    'package-title-top-border',
    'relative',
    'df',
    'flex-column',
    additionalClasses,
  );

  const isTodayVertical = false;
  const isNotAlternateDisplay = false;
  const newsVertical = vertical === 'news';

  const link = seeAllUrl
    ? (
      <Link
        className={classNames(
          'package-title-top-border__link',
          {
            'package-title-top-border__link--alt-color': isTodayVertical && isNotAlternateDisplay,
          },
        )}
        to={seeAllUrl}
        icid={icid}
      >
        {seeAllText || i18next.t('See All')}
      </Link>
    )
    : null;

  return (
    <div className={containerClasses} data-testid="package-title-top-border">
      <div className="package-title-top-border__top-decoration" data-test="topDecoration" />
      {newsVertical && <hr className="package-title-top__logo-line" />}
      <HTag className={headingClasses}>
        <TitleLogo logoUrl={logoUrl} title={title} />
        {link}
      </HTag>
    </div>
  );
}

PackageTitleTopBorder.propTypes = {
  additionalClasses: PropTypes.string,
  logoUrl: PropTypes.string,
  seeAllText: PropTypes.string,
  seeAllUrl: PropTypes.string,
  title: PropTypes.string,
  icid: PropTypes.string,
  HTag: PropTypes.string,
};
