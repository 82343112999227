import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as CustomPropTypes from 'lib/CustomPropTypes';

import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import PackageHeader from 'components/PackageHeader';
import TitleLogo from 'components/TitleLogo';

import styles from './styles.module.scss';

/**
 * Component to add title information to bundles of packaged content. Can be
 * rendered multiple times on a page. Also renders a 'See All' button to link
 * to additional content packages.
 */
const CollectionTitle = (props) => {
  const {
    alignRight = false,
    content = {},
    pkgClassName = 'pkg',
  } = props;

  const {
    metadata = {},
    packageIndex,
  } = content;

  const {
    description,
    logoUrl,
    seeAllText,
    seeAllUrl,
    title,
  } = metadata || {};

  const collectionTitleCx = classNames(
    styles.collectionTitle,
    pkgClassName,
    {
      [styles.mirror]: alignRight,
    },
  );

  return (
    <section className={collectionTitleCx}>
      {(logoUrl || title) && (
        <PackageHeader className={styles.title} packageIndex={packageIndex}>
          <TitleLogo logoUrl={logoUrl} title={title} />
        </PackageHeader>
      )}
      <p className={styles.dek}>
        {description}
      </p>
      {seeAllUrl && (
        <ButtonHoverAnimation
          additionalClasses={styles.button}
          hasDarkBackground
          size="normal"
          title={seeAllText}
          type="link"
          url={seeAllUrl}
        />
      )}
    </section>
  );
};

CollectionTitle.propTypes = {
  alignRight: PropTypes.bool,
  content: CustomPropTypes.package.isRequired,
  pkgClassName: PropTypes.string,
};

CollectionTitle.contextTypes = {
  ...CustomPropTypes.layoutContext,
};

export { CollectionTitle };
