import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  playmakerMetadata as PlaymakerMetadataPropType,
  CvsdkTeaseStyle as CvsdkTeaseStylePropType, unibrow as UnibrowPropType,
} from 'lib/CustomPropTypes';
import { LayoutContext } from 'lib/ContextTypes/layout';
import { isValidAbsoluteUrl } from 'lib/urlUtils';
import { isTelemundoVertical } from 'lib/vertical';
import {
  isAutoplay,
  NEWS_NOW_DEFAULT_SLATE,
  TODAY_ALL_DAY_DEFAULT_SLATE,
  MSNBC_DEFAULT_SLATE,
  NOTICIAS_AHORA_DEFAULT_SLATE,
  TELEMUNDO_AL_DIA_DEFAULT_SLATE,
  STREAM_KEYS,
} from 'lib/liveVideo';

import { Dek } from 'components/Dek';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import VideoSlate from 'components/PlaymakerTease/Slate';
import { OmegaFeatureFlagSwitch } from 'components/OmegaVideoPlayer/OmegaSwitch';
import { VideoContainer } from 'components/VideoContainer';
import { CoreVideoPlayer } from 'components/CoreVideoPlayer';
import { VideoControlRack } from 'components/VideoContainer/VideoControlRack';
import { TapToUnmuteButton } from 'components/VideoContainer/TapToUnmuteButton';
import { Unibrow } from 'components/Unibrow';
import BadgeLabel from 'components/BadgeLabel';

/**
 *
 * @param {string} brand
 */
export const getDefaultSlateImage = (brand) => {
  switch (brand) {
    case 'news':
      return NEWS_NOW_DEFAULT_SLATE;
    case 'today':
      return TODAY_ALL_DAY_DEFAULT_SLATE;
    case 'msnbc':
      return MSNBC_DEFAULT_SLATE;
    default:
      return NEWS_NOW_DEFAULT_SLATE;
  }
};
/**
 *
 * @param {string} streamId
 */
export const getDefaultSlateImageByStream = (streamId) => {
  switch (streamId) {
    case STREAM_KEYS.NOTICIAS_AHORA:
      return NOTICIAS_AHORA_DEFAULT_SLATE;
    case STREAM_KEYS.TELEMUNDO_AL_DIA:
      return TELEMUNDO_AL_DIA_DEFAULT_SLATE;
    default:
      return NEWS_NOW_DEFAULT_SLATE;
  }
};
/**
 * Displays a default CVSDK tease with various configurations.
 *
 * @param {object} props
 * @param {string} props.block
 * @param {object} props.playmakerMetadata
 * @param {boolean} props.hasClickedToPlay
 * @param {Function} props.onPlayClick
 * @param {string} props.streamId
 * @param {boolean} props.showDek
 * @param {boolean} props.showTitle
 * @param {CvsdkStyleOverride} props.styleOverride
 * @param {string} props.vertical
 * @param {boolean} props.isStoryline
 * @param {Function} props.onStuckChange
 * @param {Unibrow} props.unibrow
 * @param {boolean} props.noStuckChangeInFullscreen
 */
function CvsdkTeaseDefault(props) {
  const { t } = useTranslation();

  const { isFluidWidthPage } = React.useContext(LayoutContext);

  const [hasClickedToPlay, setHasClickedToPlay] = React.useState(false);

  const {
    block,
    playmakerMetadata,
    hasClickedToPlay: hasClickedToPlayProps,
    onPlayClick,
    streamId,
    showDek,
    showTitle,
    styleOverride,
    vertical,
    unibrow,
    isStoryline,
    onStuckChange,
    noStuckChangeInFullscreen,
  } = props;

  const {
    playmakerArt,
    playmakerAutoplayMuted,
    playmakerDescription,
    playmakerFlag,
    playmakerPlayBehavior,
    playmakerTitle,
    videoTitleToggle = true,
  } = playmakerMetadata;

  /**
 * Handles the play button click event.
 */
  const handlePlayClick = () => {
    // Trigger playClick prop function
    onPlayClick();
    // Update state,
    setHasClickedToPlay(true);
  };

  const isVideoPlaying = hasClickedToPlay || hasClickedToPlayProps || isAutoplay(playmakerMetadata);

  const isAutoplayMuted = playmakerAutoplayMuted !== false; // default play muted
  const defaultSlateImage = isTelemundoVertical(vertical)
    ? getDefaultSlateImageByStream(streamId)
    : getDefaultSlateImage(vertical);
  const videoTitle = showTitle && (videoTitleToggle && (playmakerTitle || `Watch ${vertical.toUpperCase()}`));

  // If user defined the playmaker art as a static image, use that instead of preview
  const isArtImageUrl = isValidAbsoluteUrl(playmakerArt);

  const isLive = playmakerFlag === 'Live';
  /**
   * Renders the flag or badge component based on the storyline and playmaker metadata.
   */
  const flagOrBadge = () => {
    if (isStoryline) {
      return (
        <BadgeLabel
          badgeType={playmakerMetadata.playmakerBadge}
          badgeText={playmakerMetadata.playmakerBadgeText}
          playmakerBadge
        />
      );
    }

    if (playmakerFlag || isLive) {
      return (
        <span
          data-testid="playmaker-flag"
          className={classNames(
            `${block}__flag`,
          )}
        >
          <InlineBlinkingLiveDot className={`${block}__dot`} />
          {playmakerFlag || t('Live')}
          &nbsp;
          {'/ '}
        </span>
      );
    }

    return <></>;
  };

  return (
    <section className={block} data-testid="cvsdk-tease-default">
      <div className={styleOverride.gridOuter}>
        <div className={styleOverride.gridCol1}>
          <div className={`${block}__video-outer`}>
            <div className={`${block}__video-inner`}>
              {(isVideoPlaying) && (
                <OmegaFeatureFlagSwitch
                  ifOmega={(
                    <OmegaVideoPlayer
                      autoplay={playmakerPlayBehavior === 'autoplay'}
                      mutedAutoplay={isAutoplayMuted}
                      stream={streamId}
                      hasClickedToPlay={hasClickedToPlay}
                      stickyEnabled
                      onStuckChange={onStuckChange}
                      noStuckChangeInFullscreen={noStuckChangeInFullscreen}
                    />
                  )}
                >
                  <VideoContainer>
                    <TapToUnmuteButton />
                    <CoreVideoPlayer
                      stream={streamId}
                      playmakerArt={playmakerArt}
                      autoplay={playmakerPlayBehavior === 'autoplay'}
                      mutedAutoplay={isAutoplayMuted}
                    />
                    <VideoControlRack />
                  </VideoContainer>
                </OmegaFeatureFlagSwitch>
              )}
              {!isVideoPlaying && (
                <VideoSlate
                  isLive={isLive}
                  pageView="front"
                  video={{
                    primaryImage: {
                      url: {
                        primary: isArtImageUrl ? playmakerArt : defaultSlateImage,
                      },
                    },
                    playable: true,
                  }}
                  onPlayClick={handlePlayClick}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styleOverride.gridCol2}>
          {isFluidWidthPage ? (
            <Unibrow
              unibrow={unibrow}
              hasDefaultTextStyle
              vertical={vertical}
            />
          ) : null}
          <h3
            className={classNames(
              `${block}__headline`,
              { storyline: isStoryline },
              styleOverride.headline,
            )}
          >
            <span>
              {flagOrBadge()}
              <span>{videoTitle}</span>
            </span>
          </h3>
          {showDek && (
            <Dek
              dek={playmakerDescription}
              className={`${block}__dek`}
            />
          )}
        </div>
      </div>
    </section>
  );
}

CvsdkTeaseDefault.propTypes = {
  unibrow: UnibrowPropType,
  block: PropTypes.string.isRequired,
  hasClickedToPlay: PropTypes.bool,
  onPlayClick: PropTypes.func,
  playmakerMetadata: PlaymakerMetadataPropType.isRequired,
  streamId: PropTypes.oneOf(Object.values(STREAM_KEYS)).isRequired,
  showDek: PropTypes.bool,
  showTitle: PropTypes.bool,
  styleOverride: CvsdkTeaseStylePropType.isRequired,
  vertical: PropTypes.string.isRequired,
  isStoryline: PropTypes.bool,
  onStuckChange: PropTypes.func,
  noStuckChangeInFullscreen: PropTypes.bool,
};

CvsdkTeaseDefault.defaultProps = {
  unibrow: undefined,
  hasClickedToPlay: false,
  onPlayClick: Function.prototype,
  showDek: true,
  showTitle: true,
  isStoryline: false,
  onStuckChange: Function.prototype,
  noStuckChangeInFullscreen: false,
};

export { CvsdkTeaseDefault };
